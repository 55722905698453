<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card class="SearchBox" v-loading="loading">
      <el-row>
        <el-form :model="searchFormData" :inline="true">
          <el-form-item label="所属门店" prop="shopId">
            <el-select v-model="searchFormData.shopId" placeholder="请选择">
              <el-option v-for="item in shopDrops" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="昵称" prop="nickName">
            <el-input v-model="searchFormData.nickName"></el-input>
          </el-form-item>
          <el-form-item label="账号" prop="userName">
            <el-input v-model="searchFormData.userName"></el-input>
          </el-form-item>
          <el-button type="primary" @click="() => ToSearch()">搜索</el-button>
          <el-button type="primary" @click="() => ToAddPage()" style="float:right;">新增</el-button>
        </el-form>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="nickName" label="昵称" width="180" />
        <el-table-column prop="shopName" label="所在店" width="180" />
        <el-table-column prop="userName" label="账号" width="180" />
        <el-table-column prop="createTime" label="创建时间" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="OpenStoreUserDialog(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            <el-button size="mini" type="info" @click="CheckRecord(scope.$index, scope.row)">核销记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-pagination layout="prev, pager, next" :total="pageInfo.totalCount" :current-page="pageInfo.pageIndex"
          :page-size="pageInfo.pageSize" />
      </div>
    </el-card>
    <el-dialog :title="shopDialogState" :visible.sync="showDialog" width="30%" :before-close="handleClose">
      <el-form label-position="right" :model="formData" label-width="80px" v-loading.lock="loading" ref="editForm"
        :rules="formRule">
        <el-form-item label="所属门店" prop="nickName">
          <el-select v-model="formData.shopId" placeholder="请选择">
            <el-option v-for="item in shopDrops" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="formData.nickName"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="userName">
          <el-input v-model="formData.userName"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="passWord">
          <el-input v-model="formData.passWord"></el-input>
        </el-form-item>
        <el-row shadow="never" class="">
          <el-button type="primary" @click="SubmitCreate()" :loading="loading">
            提交
          </el-button>
          <el-button @click="CloseDialog">
            关闭
          </el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { queryShopUserList, editShopUser, queryShopUser, deleteShopUser } from '@/service/shopuser.js';
import { dropShop } from '@/service/shop.js';
import { shopFormRule } from '@/utlis/rules/activityRule.js';
export default {
  data () {
    return {
      shopDrops: [
        {
          label: '--全部--',
          value: 0,
        }
      ],
      formRule: shopFormRule,
      showDialog: false,
      loading: false,
      pageInfo: {
        pageIndex: 1,
        pageSize: 30,
        totalCount: 0,
      },
      tableData: [],
      formData: {
        id: 0,
        shopId: 0,
        userName: '',
        nickName: '',
        passWord: '',

      },
      searchFormData: {
        shopId: 0,
        nickName: '',
        userName: '',
      }
    }
  },
  computed: {
    shopDialogState () {
      if (this.formData.id > 0)
        return "编辑";
      else
        return "新增";
    }
  },
  created () {
    this.DataBinding();
  },
  methods: {
    //加载数据
    DataBinding () {

      this.DropStores();

    },
    //获取门店员工数据
    AllSalerList () {
      this.loading = true;
      let searchParam = Object.assign(this.pageInfo, this.searchFormData);
      queryShopUserList(searchParam).then(res => {
        this.tableData = res.data.data;
        this.pageInfo.pageIndex = res.data.pageIndex,
          this.pageInfo.pageSize = res.data.pageSize,
          this.pageInfo.totalCount = res.data.totalCount,
          this.loading = false;
      }).catch(ex => {
        this.loading = false;
      })
    },
    //查找指定店员
    OpenStoreUserDialog (index, row) {
      this.loading = true;
      queryShopUser(row.id).then(res => {
        console.log(res);
        this.loading = false;
      }).catch(ex => {
        this.loading = false;
      })

    },
    //获取门店下拉框数据
    DropStores () {
      dropShop().then(res => {
        this.shopDrops = [{
          label: '--全部--',
          value: 0,
        }];
        if (res.code == 0) {
          res.data.forEach(element => {
            this.shopDrops.push(element);
            this.AllSalerList();
          });
        }
      });
    },
    //新增
    ToAddPage () {
      this.formData.id = 0;
      this.formData.shopId = 0;
      this.formData.nickName = '';
      this.formData.userName = '';
      this.formData.passWord = '';

      this.showDialog = true;
    },
    //删除
    handleDelete (index, row) {
      this.loading = true;
      deleteShopUser(row.id).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功');
          this.DataBinding();
        } else {
          this.$message.error('删除失败，请刷新页面重试');
        }
        this.loading = false;
      }).catch(ex => {
        this.loading = false;
      })
    },
    //关闭窗口
    handleClose () { },
    //关闭弹窗
    CloseDialog () {
      this.showDialog = false;
      this.DataBinding();
    },
    //手动搜索
    ToSearch () {
      this.DataBinding();
    },
    //新增员工
    SubmitCreate () {
      this.loading = true;
      this.formData.shopId = parseInt(this.formData.shopId);
      let _this = this;
      editShopUser(this.formData).then(res => {
        console.log('smldlsdlsdk', res)
        if (res.code === 0) {
          _this.$message.success('新增店员成功');
          this.showDialog = false;
          this.DataBinding();
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      });
    }
  }
}
</script>
<style scoped Lang="scss">
.SearchBox {
  margin-bottom: 10px;
}
</style>